import type { UserAccount } from '@orus.eu/backend/src/views/user-account-view'
import { PARIS, getStartOfCalendarDate, getZonedDateTimeFromMillis, type CalendarDate } from '@orus.eu/calendar-date'
import { leftPad } from '@orus.eu/leftpad'
import { DateTime } from 'luxon'
import { locale } from './i18n'

export function formatDate(date: Date | number): string {
  const displayedDate = typeof date === 'number' ? new Date(date) : date
  const year = displayedDate.getFullYear().toString(10)
  const month = leftPad(displayedDate.getMonth() + 1, 2)
  const day = leftPad(displayedDate.getDate(), 2)
  return `${day}/${month}/${year}`
}

export function formatDateTime(date: Date | number | null | undefined): string {
  if (!date) {
    return '-'
  }
  const displayedDate = typeof date === 'number' ? new Date(date) : date
  const hour = leftPad(displayedDate.getHours(), 2)
  const minute = leftPad(displayedDate.getMinutes(), 2)
  return `${formatDate(displayedDate)} - ${hour}:${minute}`
}

export function formatDateTimeText(date: Date | number | null | undefined): string {
  if (!date) {
    return '-'
  }
  const displayedDate = typeof date === 'number' ? new Date(date) : date
  const luxonDate = DateTime.fromJSDate(displayedDate, { zone: PARIS })
  const formattedDate = luxonDate.toFormat('d LLLL yyyy', { locale: 'fr' })
  const formattedHour = luxonDate.toFormat('T')

  return `${formattedDate} à ${formattedHour}`
}

export function formatDateTimeSeconds(date: Date | number): string {
  const displayedDate = typeof date === 'number' ? new Date(date) : date
  return `${formatDateTime(date)}:${leftPad(displayedDate.getSeconds(), 2)}`
}

export function formatCalendarDate(date: CalendarDate): string {
  return formatTimestamp(getStartOfCalendarDate(date, PARIS).toMillis())
}

export function getUserLabel(user: UserAccount): string {
  if (user.firstName && user.lastName) {
    return `${user.firstName} ${user.lastName}`
  }

  if ('email' in user) {
    return user.email
  }

  return `${user.unverifiedEmail} (unverified)`
}

export function formatTimestamp(timestamp: number): string {
  const luxonDatetime = getZonedDateTimeFromMillis(timestamp).setZone(PARIS).setLocale(locale)
  return `${luxonDatetime.toLocaleString(DateTime.DATE_FULL)} à ${luxonDatetime.toLocaleString(DateTime.TIME_SIMPLE)}`
}

export function capitalizeFirstLetter(text: string): string {
  return text.charAt(0).toUpperCase() + text.slice(1)
}
